import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import Image from 'next/image'
import {Bullet} from '@/_new-code/products/flexible-web-toolkit/components/bullet'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {BulletContentItem} from '../types'
import {RichTextRenderer} from '../../../components/rich-text-renderer'
import type {CtaButtonContentItem} from '../../cta-button'
import {ReusableCTAButtonComponent} from '../../cta-button'
import {Auxiliary} from '../../../components/auxiliary'

export type ListerBlockWithBulletsContentItem = IContentItem<{
	tabTitle: Elements.TextElement
	title: Elements.TextElement
	description: Elements.RichTextElement
	bullets: Elements.LinkedItemsElement<BulletContentItem>
	image: Elements.AssetsElement
	secondDescription: Elements.RichTextElement
	button: Elements.LinkedItemsElement<CtaButtonContentItem>
}>

export const ListerBlockWithBullets: Block<
	ListerBlockWithBulletsContentItem
> = ({block, ...context}) => {
	return (
		<Auxiliary>
			<h3>{block.elements.title}</h3>
			<RichTextRenderer
				className="pt-6"
				data-kontent-element-codename="description"
				element={block.elements.description}
				{...context}
			/>
			<Bullet className="max-w-md pt-4">
				{block.elements.bullets.map(({elements, system}) => (
					<li key={system.id}>{elements.item}</li>
				))}
			</Bullet>
			<RichTextRenderer
				className="pb-6"
				data-kontent-element-codename="second_description"
				element={block.elements.secondDescription}
				{...context}
			/>
			{block.elements.button.map((button) => (
				<ReusableCTAButtonComponent
					block={button}
					key={button.system.id}
					{...context}
				/>
			))}
			{block.elements.image[0] ? (
				<Image
					alt={block.elements.image[0].description ?? ''}
					data-kontent-element-codename="image"
					height={
						block.elements.image[0].height &&
						block.elements.image[0].height > 0
							? block.elements.image[0].height
							: 600
					}
					loader={loadImageFromKontentAI}
					src={block.elements.image[0].url}
					width={
						block.elements.image[0].width &&
						block.elements.image[0].width > 0
							? block.elements.image[0].width
							: 400
					}
				/>
			) : null}
		</Auxiliary>
	)
}
