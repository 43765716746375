import {useEffect, useImperativeHandle, useRef, useState} from 'react'

interface OptionType {
	activeParent?: HTMLElement | null
	interval?: number
	ref?: React.Ref<HTMLElement | null>
	sensitivity?: number
	timeout?: number
}

export const useHoverIntent = <T = HTMLElement>(
	options?: OptionType
): [boolean, React.RefObject<HTMLElement & T>] => {
	const {
		activeParent = null,
		interval = 50,
		ref,
		sensitivity = 6,
		timeout = 0,
	} = options ?? {}
	const intentRef = useRef<(HTMLElement & T) | null>(null)
	const [isHovering, setIsHovering] = useState(false)

	let x = 0
	let y = 0
	let pX = 0
	let pY = 0
	let timer = 0
	const delay = (): void => {
		if (timer) {
			clearTimeout(timer)
		}
		setIsHovering(false)
	}
	const tracker = (e: MouseEvent): void => {
		x = e.clientX
		y = e.clientY
	}
	const compare = (e: MouseEvent): void => {
		if (timer) {
			clearTimeout(timer)
		}
		if (Math.abs(pX - x) + Math.abs(pY - y) < sensitivity) {
			setIsHovering(true)
			return
		}
		pX = x
		pY = y
		timer = window.setTimeout(() => {
			compare(e)
		}, interval)
	}
	const dispatchOver = (e: MouseEvent): void => {
		if (timer) {
			clearTimeout(timer)
		}
		if (intentRef.current) {
			intentRef.current.removeEventListener('mousemove', tracker, false)
		}
		if (!isHovering) {
			pX = e.clientX
			pY = e.clientY
			if (intentRef.current) {
				intentRef.current.addEventListener('mousemove', tracker, false)
			}
			timer = window.setTimeout(() => {
				compare(e)
			}, interval)
		}
	}
	const dispatchOut = (): void => {
		if (timer) {
			clearTimeout(timer)
		}
		if (intentRef.current) {
			intentRef.current.removeEventListener('mousemove', tracker, false)
		}
		if (isHovering) {
			timer = window.setTimeout(() => {
				delay()
			}, timeout)
		}
	}

	if (
		!activeParent ||
		(intentRef.current &&
			!activeParent.contains(intentRef.current) &&
			!intentRef.current.contains(activeParent))
	) {
		dispatchOut()
	}

	useEffect(() => {
		const currentRef = intentRef.current
		if (currentRef) {
			currentRef.addEventListener('mouseover', dispatchOver, false)
			currentRef.addEventListener('mouseout', dispatchOut, false)
		}

		return () => {
			if (currentRef) {
				currentRef.removeEventListener('mouseover', dispatchOver, false)
				currentRef.removeEventListener('mouseout', dispatchOut, false)
			}
		}
	})

	useImperativeHandle(ref, () => intentRef.current as HTMLElement, [
		intentRef,
	])

	return [isHovering, intentRef as React.RefObject<HTMLElement & T>]
}
