import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	isValidElement,
	cloneElement,
	Children,
} from 'react'
import type {ReactElement, PropsWithChildren} from 'react'
import {UIDConsumer} from 'react-uid'
import {getElementName} from 'react-children-utilities'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'
import {useHoverIntent} from '@/_new-code/utilities/hooks/use-hover-intent'
import styles from './navigation.module.css'

interface SubNavProps {
	children?:
		| string
		| ReactElement<
				PropsWithChildren<{
					className?: string
					dangerouslySetInnerHTML?: {__html: string}
				}>
		  >
		| ReactElement<
				PropsWithChildren<{
					className?: string
					dangerouslySetInnerHTML?: {__html: string}
				}>
		  >[]

	offset?: number
}

const SubNav = ({children, offset = 0}: SubNavProps): React.JSX.Element => {
	const [isContextUsed, setIsContextUsed] = useState<boolean>(false)
	const subNavRef = useRef<HTMLUListElement | null>(null)
	const [positionTopValue, setPositionTopValue] = useState<number | null>(
		null
	)
	const [addScrollBar, setAddScrollBar] = useState<boolean>(false)

	useEffect(() => {
		setIsContextUsed(Boolean(document.querySelector('#theme')))

		const updatePosition = (): void => {
			if (subNavRef.current) {
				const elementPosition =
					subNavRef.current.getBoundingClientRect()
				const windowHeight = window.innerHeight
				if (elementPosition.bottom > windowHeight) {
					if (elementPosition.top > 200) {
						setPositionTopValue(
							windowHeight - elementPosition.bottom
						)
					} else {
						setPositionTopValue(null)
					}
				} else {
					setPositionTopValue(null)
				}
			}
		}

		window.addEventListener('resize', updatePosition)
		updatePosition()
		if (!subNavRef.current?.querySelector('ul')) {
			setAddScrollBar(true)
		}
		return () => {
			window.removeEventListener('resize', updatePosition)
		}
	}, [])

	const backgroundColor = isContextUsed ? 'bg-main' : 'bg-white'
	return (
		<ul
			className={`subnav ${backgroundColor} ${
				addScrollBar ? 'max-h-96 overflow-y-auto' : ''
			}`}
			ref={subNavRef}
			style={{
				transform: `translateX(${offset}px)`,
				...(positionTopValue
					? {position: 'absolute', top: `${positionTopValue}px`}
					: {}),
			}}
		>
			{children}
		</ul>
	)
}

interface NavItemProps {
	activeEl: HTMLElement | null
	allowScrollingNav?: boolean
	brandColor?: string
	handleClick?: (
		e: React.MouseEvent<
			HTMLAnchorElement | HTMLButtonElement | HTMLLIElement
		>
	) => void
	isMobileMenu: boolean | undefined
	isSubNav?: boolean
	item: React.ReactElement<{
		selected: boolean
		className: string
		children: React.ReactNode
	}>
	parentOffsetX?: number
	openByDefault?: boolean
	openSubNavOnLinkClick?: boolean
}

const NavItem = ({
	activeEl,
	allowScrollingNav,
	brandColor,
	handleClick,
	isMobileMenu,
	isSubNav = false,
	item,
	parentOffsetX = 0,
	openByDefault = false,
	openSubNavOnLinkClick = false,
}: NavItemProps): React.JSX.Element => {
	const ref = useRef<HTMLLIElement>(null)
	const [isHovering] = useHoverIntent({activeParent: activeEl})
	const showSubmenu =
		(isHovering && !isMobileMenu) ||
		(ref.current &&
			(activeEl === ref.current || ref.current.contains(activeEl))) ||
		(isMobileMenu && openByDefault)

	const childrenArr = Array.isArray(item.props.children)
		? item.props.children
		: [item.props.children]

	const subChildren = childrenArr.find(
		(x): x is React.ReactElement =>
			isValidElement(x) &&
			(getElementName(x) === SubNav.name || x.type === SubNav)
	)
	const hasSubnav = Boolean(subChildren)
	let listeners: React.HTMLProps<HTMLLIElement> = {}
	if (hasSubnav && handleClick) {
		listeners = {
			onClick: (e) => {
				handleClick(e)
			},
		}
	}
	let offset = 0
	if (ref.current && !isMobileMenu) {
		const widthWithoutPadding =
			ref.current.offsetWidth -
			parseFloat(window.getComputedStyle(ref.current).paddingLeft)
		const submenu = ref.current.querySelector('ul')

		if (submenu !== null) {
			offset =
				-(submenu.offsetWidth - widthWithoutPadding) - parentOffsetX
		}
		if (ref.current.offsetLeft + offset <= 0) {
			offset = 0
		}
	}
	const link = hasSubnav
		? (childrenArr.find(
				(childItem): childItem is React.ReactElement =>
					isValidElement(childItem) &&
					(getElementName(childItem) !== SubNav.name ||
						childItem.type === SubNav)
			) as React.ReactElement<{
				className?: string
				children: React.ReactNode
				'aria-haspopup'?: boolean
				'aria-expanded'?: boolean
				style?: React.CSSProperties
			}> | null)
		: null

	const clonedLink = link
		? cloneElement(link, {
				'aria-haspopup': true,
				'aria-expanded': showSubmenu,
			})
		: null

	const openNavItemButton = link ? (
		<button
			aria-expanded={showSubmenu}
			aria-haspopup
			className={`${link.props.className ?? ''} p-4 text-left ${
				showSubmenu ? '' : 'text-blue-900'
			}`}
			style={link.props.style ? {...link.props.style} : {}}
			type="button"
		>
			{link.props.children}
		</button>
	) : null
	let newChildren: React.ReactElement<
		PropsWithChildren<{
			className?: string
			dangerouslySetInnerHTML?: {__html: string}
		}>
	>[] = []

	if (hasSubnav && isValidElement(subChildren)) {
		const subChildrenAsReactElement = subChildren as React.ReactElement<{
			children: React.ReactNode
		}>
		const childrenArray = Children.toArray(
			subChildrenAsReactElement.props.children
		).filter(isValidElement)
		newChildren = childrenArray.map((curr) => {
			return (
				<div
					className={isSubNav && !isMobileMenu ? 'w-64' : ''}
					key={curr.key || Math.random()}
				>
					{isValidElement(curr) && (
						<NavItem
							activeEl={activeEl}
							brandColor={brandColor}
							isMobileMenu={isMobileMenu}
							isSubNav
							item={
								curr as React.ReactElement<{
									selected: boolean
									className: string
									children: React.ReactNode
								}>
							}
							openByDefault={openByDefault}
							openSubNavOnLinkClick={openSubNavOnLinkClick}
							parentOffsetX={parentOffsetX}
						/>
					)}
				</div>
			)
		})
	}

	const hoverNavBrandColor = brandColor
		? `[&>a]:hover:!text-${brandColor} [&>a]:after:hover:!bg-${brandColor}`
		: ''
	const selectedNavBrandColor = brandColor
		? `[&>a]:!text-${brandColor} [&>a]:after:!bg-${brandColor}`
		: ''
	return (
		<li
			{...item.props}
			{...listeners}
			className={`
      ${item.props.className || ''} 
      ${showSubmenu ? `${styles.open} ${hoverNavBrandColor}` : ''}
      ${allowScrollingNav ? 'shrink-0 whitespace-nowrap' : ''} 
      ${isSubNav ? styles.subNavListItem : ''} 
      ${!isSubNav ? 'lg:mr-4' : ''}
      ${
			item.props.selected
				? `${styles.selected} text-gray-600 ${selectedNavBrandColor}`
				: ''
		}
    `}
			key={item.key}
			ref={ref}
		>
			{hasSubnav ? (
				<UIDConsumer>
					{(id, uid) => (
						<React.Fragment key={uid(subChildren)}>
							{isMobileMenu && openSubNavOnLinkClick
								? openNavItemButton
								: clonedLink}
							<button
								aria-expanded={showSubmenu}
								className={`${
									isSubNav ? styles.subNavChevron : ''
								} ${styles.subNavButton}`}
								type="button"
							>
								<span className="visuallyhidden">
									Show submenu for “
									{(link && link.props.children) || ''}”
								</span>
							</button>
							<SubNav offset={isSubNav ? 0 : offset}>
								{newChildren}
							</SubNav>
						</React.Fragment>
					)}
				</UIDConsumer>
			) : (
				childrenArr
			)}
		</li>
	)
}

interface NavProps {
	brandColor?: string
	children: React.ReactNode
	className?: string
	isGlobalNav?: boolean
	label?: string
	scrollable?: boolean
}

export const Nav = ({
	brandColor,
	children,
	className = '',
	isGlobalNav = false,
	label = 'Main Navigation',
	scrollable = false,
}: NavProps): React.JSX.Element => {
	const {isMobile, isTablet} = useWindowSize()
	const [state, setState] = useState({
		activeEl: null as HTMLElement | null,
		offset: 0,
		showNavArrows: false,
		leftArrowDisabled: false,
		rightArrowDisabled: false,
		ulWidth: 0,
	})
	const activeElRef = useRef<HTMLElement | null>(state.activeEl)
	const setActiveEl = useCallback((el: HTMLElement | null): void => {
		activeElRef.current = el
		setState((prevState) => ({
			...prevState,
			activeEl: el,
		}))
	}, [])

	const [navHovered, setNavHovered] = useState<boolean>(false)
	const allowScrollingNav = scrollable && !isGlobalNav
	const $ulEl = useRef<HTMLUListElement | null>(null)
	const $navEl = useRef<HTMLDivElement | null>(null)
	let isMouseDown = false
	let startX: number
	let scrollLeft: number

	function disableArrows(): void {
		const ulElement = $ulEl.current

		if (ulElement) {
			const disableRightArrow =
				state.ulWidth === ulElement.offsetWidth + ulElement.scrollLeft

			setState((prevState) => ({
				...prevState,
				leftArrowDisabled: ulElement.scrollLeft === 0,
				rightArrowDisabled: disableRightArrow,
			}))
		}
	}
	function onMouseDown(e: React.MouseEvent): void {
		if ($ulEl.current) {
			isMouseDown = true
			startX = e.pageX - $ulEl.current.offsetLeft
			scrollLeft = $ulEl.current.scrollLeft
		}
	}
	function onMouseLeave(): void {
		isMouseDown = false
		disableArrows()
	}

	function onMouseUp(): void {
		isMouseDown = false
		disableArrows()
	}

	function onMouseMove(e: React.MouseEvent): void {
		if (!isMouseDown) return

		e.preventDefault()

		const ulElement = $ulEl.current

		if (ulElement) {
			const x = e.pageX - ulElement.offsetLeft
			const walk = x - startX
			ulElement.scrollLeft = scrollLeft - walk
		}
	}

	function leftScroll(): void {
		const ulElement = $ulEl.current

		if (ulElement) {
			ulElement.scrollLeft -= 100
			disableArrows()
		}
	}

	function rightScroll(): void {
		const ulElement = $ulEl.current

		if (ulElement) {
			ulElement.scrollLeft += 100
			disableArrows()
		}
	}

	useEffect(() => {
		function checkListWidth(): void {
			if (!allowScrollingNav) return

			const ulElement = $ulEl.current
			const navElement = $navEl.current

			if (ulElement && navElement) {
				let ulWidth = 0

				Array.from(ulElement.children).forEach((item) => {
					ulWidth += (item as HTMLElement).offsetWidth
				})

				setState((prevState) => ({
					...prevState,
					ulWidth,
					showNavArrows: ulWidth > navElement.offsetWidth,
					leftArrowDisabled: ulElement.scrollLeft === 0,
					rightArrowDisabled:
						ulWidth ===
						ulElement.offsetWidth + ulElement.scrollLeft,
				}))
			}
		}

		checkListWidth()
		window.addEventListener('resize', checkListWidth)

		return () => {
			window.removeEventListener('resize', checkListWidth)
		}
	}, [$ulEl, $navEl, allowScrollingNav])

	function handleClick(e: React.MouseEvent): void {
		const target = (e.target as HTMLElement).closest('li')

		if (target) {
			const parentNode = target.parentNode

			if (
				activeElRef.current &&
				parentNode &&
				(parentNode as HTMLElement).closest('ul.subnav') === null &&
				target.contains(activeElRef.current)
			) {
				setActiveEl(null)
				return
			}

			if (activeElRef.current !== target) {
				setActiveEl(target)
				return
			}

			const parentLi = parentNode
				? (parentNode as HTMLElement).closest('li')
				: null
			if (parentLi) {
				setActiveEl(parentLi)
			}
		}
	}
	const renderedChildren = Children.toArray(children).map((item) => {
		let key

		if (isValidElement(item)) {
			const {id} = item.props as {id?: string}
			key = item.key || (id ? String(id) : Math.random().toString(36))
		} else {
			key = Math.random().toString(36)
		}

		return (
			<NavItem
				activeEl={state.activeEl}
				brandColor={brandColor}
				handleClick={handleClick}
				isMobileMenu={isMobile || isTablet}
				item={
					item as React.ReactElement<{
						selected: boolean
						className: string
						children: React.ReactNode
					}>
				}
				key={key}
				openByDefault={false}
				openSubNavOnLinkClick={false}
				parentOffsetX={$ulEl.current?.scrollLeft}
			/>
		)
	})
	const checkMouseLeave = useCallback(
		(e: MouseEvent) => {
			const activeEl = (e.target as HTMLElement).closest('li')
			if (activeEl && activeEl !== activeElRef.current) {
				setActiveEl(activeEl as HTMLElement)
			}
			if (
				$navEl.current &&
				!$navEl.current.contains(e.target as HTMLElement)
			) {
				setNavHovered(false)
				setActiveEl(null)
				window.removeEventListener('mousemove', checkMouseLeave)
			}
		},
		[setActiveEl]
	)
	return (
		<nav
			aria-label={label}
			className={`${styles.nav} ${className} container-wide ${
				label === 'Main Navigation'
					? 'h-max max-h-3/4 overflow-y-scroll pb-20 pt-4'
					: ''
			} lg:h-auto lg:max-h-screen lg:overflow-y-auto lg:pb-0 lg:pt-0 ${
				allowScrollingNav ? 'items-center lg:flex' : ''
			}`}
			onBlur={() => {
				setNavHovered(false)
				setActiveEl(null)
				window.removeEventListener('mousemove', checkMouseLeave)
			}}
			onFocus={() => {
				if (isMobile || (isTablet && isGlobalNav)) return
				if (!navHovered) {
					setNavHovered(true)
					window.addEventListener('mousemove', checkMouseLeave)
				}
			}}
			onMouseOver={() => {
				if (isMobile || (isTablet && isGlobalNav)) return
				if (!navHovered) {
					setNavHovered(true)
					window.addEventListener('mousemove', checkMouseLeave)
				}
			}}
			ref={$navEl}
		>
			<ul
				className={`text-sm font-medium text-blue-900 lg:flex ${
					allowScrollingNav ? 'no-scrollbar overflow-x-auto' : ''
				}`}
				onMouseDown={onMouseDown}
				onMouseLeave={onMouseLeave}
				onMouseMove={onMouseMove}
				onMouseUp={onMouseUp}
				ref={$ulEl}
				role="presentation"
			>
				{renderedChildren}
			</ul>

			{allowScrollingNav && state.showNavArrows ? (
				<div className="ml-6 hidden shrink-0 lg:flex">
					<button
						aria-label="Scroll Left"
						className={`mr-2 ${
							state.leftArrowDisabled
								? 'pointer-events-none text-gray-400'
								: 'cursor-pointer text-gray-600 hover:text-theme-main'
						}`}
						onClick={leftScroll}
						onKeyUp={(e) => {
							if (e.key === 'Enter') leftScroll()
						}}
						type="button"
					>
						<svg className="w-3 fill-current" viewBox="0 0 8 16">
							<path d="M8,16,0,8,8,0Z" />
						</svg>
					</button>
					<button
						aria-label="Scroll Right"
						className={
							state.rightArrowDisabled
								? 'pointer-events-none text-gray-400'
								: 'cursor-pointer text-gray-600 hover:text-theme-main'
						}
						onClick={rightScroll}
						onKeyUp={(e) => {
							if (e.key === 'Enter') rightScroll()
						}}
						type="button"
					>
						<svg className="w-3 fill-current" viewBox="0 0 8 16">
							<path d="M0,0,8,8,0,16Z" />
						</svg>
					</button>
				</div>
			) : null}
		</nav>
	)
}
Nav.SubNav = SubNav
