import type {
	Dispatch,
	FunctionComponentElement,
	SetStateAction,
	JSX,
} from 'react'
import {cloneElement, useEffect} from 'react'
import {useRouter} from 'next/router'
import Image from 'next/image'
import {clsx} from 'clsx'
import {ButtonIcons} from '@/_new-code/components/button-icons/button-icon'
import {LinkBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/link'
import {loadImageFromKontentAI} from '@/imageLoader'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import type {
	AdvancedPageTemplateContentItem,
	GlobalConfigContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import {PrimaryButtons} from '@/_new-code/products/flexible-web-toolkit/components/header/primary/buttons'
import {ToggleNav} from '@/_new-code/products/flexible-web-toolkit/components/header/primary/toggle-nav'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'
import {mapLinksWithLocale} from '@/_new-code/products/flexible-web-toolkit/components/header/primary/map-links-with-locale'
import {filterLinksByAppPermissionsAndRoles} from '@/_new-code/products/flexible-web-toolkit/components/header/primary/filter-links-by-app-permissions-and-roles'
import {Nav} from '@/_new-code/products/flexible-web-toolkit/components/navigation'
import {MainNav} from '../../main-nav'
import {NavItems} from '../../main-nav/main-nav-items'
import type {HeaderState} from '../helpers'
import {LinkWrappedImage, getNavLinks} from '../helpers'
import {CustomDropdown} from '../../custom-dropdown/custom-dropdown'
import {LinkList} from '../../link-list'
import {mainNavBorderColors} from '../../main-nav/helper'
import type {MainNavBorderColor} from '../../main-nav/helper'

interface CustomDropdownData {
	id: string | number
	title: string
	url?: string
	image: JSX.Element
}

export const PrimaryNavigation = ({
	page,
	headerState,
	globalConfig,
	setHeaderState,
	toggleSearchPopup,
}: {
	page: Tersed<AdvancedPageTemplateContentItem>
	globalConfig: Tersed<GlobalConfigContentItem>
	headerState: HeaderState
	setHeaderState: Dispatch<SetStateAction<HeaderState>>
	toggleSearchPopup: () => void
}): JSX.Element => {
	const headerConfig = page.elements.headerConfig[0]
		? page.elements.headerConfig[0]
		: globalConfig.elements.headerConfig[0]
	const authState = useAuth()
	const router = useRouter()
	useEffect(() => {
		const closeNav = (): void => {
			setHeaderState({
				...headerState,
				navVisible: false,
			})
		}
		// subscribe to next/router event
		router.events.on('routeChangeStart', closeNav)
		return () => {
			// unsubscribe to event on unmount to prevent memory leak
			router.events.off('routeChangeStart', closeNav)
		}
	}, [headerState, router.events, setHeaderState])

	const {isMobile, isTablet} = useWindowSize()

	// eslint-disable-next-line react/jsx-no-useless-fragment -- TODO: update once React types are fixed
	if (!headerConfig) return <></>

	const secondaryHeaderConfig = headerConfig.elements.secondaryHeaderConfig[0]
	const {navFixed, navVisible} = headerState

	const primaryNavHasLinks = Boolean(headerConfig.elements.links.length)
	const secondaryNavHasLinks = Boolean(
		secondaryHeaderConfig?.elements.links.length
	)
	const customDropdownHasLinks = Boolean(
		headerConfig.elements.dropdownHeaderMenu.length
	)
	const optionalPortalLinkHasLinks = Boolean(
		headerConfig.elements.optionalHeaderLinks.length
	)
	const hasLinksToDisplay =
		primaryNavHasLinks ||
		(isMobile && secondaryNavHasLinks) ||
		(isTablet && secondaryNavHasLinks)

	const headerButtons = !authState.authenticated
		? headerConfig.elements.buttonLinks
		: headerConfig.elements.loggedInCtaButton

	const species: CustomDropdownData[] =
		headerConfig.elements.dropdownHeaderMenu.map((sublink) => ({
			id: sublink.system.id,
			title: sublink.elements.text,
			url: sublink.elements.url,
			image: sublink.elements.icon[0]?.elements.icon[0] ? (
				<Image
					alt={sublink.elements.text}
					className="ml-4 h-8 w-8"
					height={32}
					loader={loadImageFromKontentAI}
					src={sublink.elements.icon[0]?.elements.icon[0]?.url}
					width={32}
				/>
			) : (
				<span />
			),
		}))

	const selectedSpecies = species.find((s) => s.url === router.asPath)
	const speciesLength = (selectedSpecies?.title.length ?? 0) >= 12
	const newIcon = (
		icon: JSX.Element,
		key: number,
		className: string
	): FunctionComponentElement<unknown> =>
		cloneElement(icon, {
			key,
			className: clsx(className, 'block fill-current overflow-visible'),
			focusable: false,
		})

	const filteredLinks = filterLinksByAppPermissionsAndRoles(
		headerConfig.elements.myAccountWrapperParent[0]?.elements
			.accountNavLinks ?? [],
		authState
	)
	const filteredauthenticatedLinks = filterLinksByAppPermissionsAndRoles(
		headerConfig.elements.authenticatedLinks,
		authState
	)

	const myAccountLinksWithLocale = mapLinksWithLocale(
		filteredLinks,
		router.locale ?? ''
	)
	const filteredAuthenticatedLinksByRoles = filteredauthenticatedLinks.filter(
		(block) => authState.checkUserRoleForBlock(block)
	)
	const authenticatedLinksWithLocale = mapLinksWithLocale(
		filteredAuthenticatedLinksByRoles,
		router.locale ?? ''
	)

	const myAccountLinks =
		authState.authenticated &&
		(isMobile || isTablet) &&
		myAccountLinksWithLocale

	const hasInPageNavigation = Boolean(secondaryHeaderConfig)

	const mainBorderColor: MainNavBorderColor =
		headerConfig.elements.borderColor[0]?.codename &&
		Object.keys(mainNavBorderColors).includes(
			headerConfig.elements.borderColor[0]?.codename
		)
			? (headerConfig.elements.borderColor[0]
					?.codename as MainNavBorderColor)
			: 'black'

	return (
		<MainNav
			brandLogo={
				!hasInPageNavigation &&
				secondaryHeaderConfig?.elements.image[0] ? (
					<LinkWrappedImage
						alt={
							secondaryHeaderConfig.elements.image[0]
								.description ?? undefined
						}
						href={secondaryHeaderConfig.elements.imageClickUrl}
						kItemId={secondaryHeaderConfig.system.id}
						src={secondaryHeaderConfig.elements.image[0].url}
					/>
				) : null
			}
			extraLogoContent={
				<>
					{customDropdownHasLinks ? (
						<CustomDropdown
							buttonClassName="p-1 pr-16 border-primary pl-2"
							className={clsx(
								speciesLength ? '' : 'w-44',
								'z-10 mx-6 hidden md:block'
							)}
							customDropdownIcon={
								<svg
									className="bottom-0 right-0 top-[8px] w-[10px]"
									clipRule="evenodd"
									fillRule="evenodd"
									imageRendering="optimizeQuality"
									shapeRendering="geometricPrecision"
									style={{
										width: 10,
										top: 8,
										bottom: 0,
										right: 0,
									}}
									textRendering="geometricPrecision"
									viewBox="0 0 512 336.36"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M42.47.01 469.5 0C492.96 0 512 19.04 512 42.5c0 11.07-4.23 21.15-11.17 28.72L294.18 320.97c-14.93 18.06-41.7 20.58-59.76 5.65-1.8-1.49-3.46-3.12-4.97-4.83L10.43 70.39C-4.97 52.71-3.1 25.86 14.58 10.47 22.63 3.46 32.57.02 42.47.01z"
										fill="black"
										fillRule="nonzero"
									/>
								</svg>
							}
							defaultSelectText={
								selectedSpecies?.title ??
								headerConfig.elements.dropdownHeaderMenu[0]
									?.elements.text
							}
							dropdownData={species}
							dropdownLabel=""
							itemClassName="w-full flex-row-reverse justify-between py-1 px-2"
							itemTitleClassName="text-base"
							listContainerClassName=""
							onChange={(data: CustomDropdownData) => {
								window.location.href = `/${
									router.locale ?? ''
								}${data.url}`
							}}
						/>
					) : null}
					{optionalPortalLinkHasLinks ? (
						<LinkList
							className={`hidden items-center capitalize underline md:flex ${
								customDropdownHasLinks || 'lg:pl-6'
							}`}
							horizontal
						>
							{headerConfig.elements.optionalHeaderLinks.map(
								(optionalHeaderLink, idx) => (
									<div
										className="w-content flex flex-row items-center justify-center"
										key={optionalHeaderLink.system.id}
									>
										{/* @ts-expect-error -- TODO: use correct component here */}
										<LinkBlock block={optionalHeaderLink} />
										<div className="pl-2 text-black">
											{newIcon(
												ButtonIcons[
													optionalHeaderLink.elements
														.icon[0]
														?.name as keyof typeof ButtonIcons
												].icon,
												idx,
												'w-3 h-3'
											)}
										</div>
									</div>
								)
							)}
						</LinkList>
					) : null}
				</>
			}
			logo={
				headerConfig.elements.image[0] ? (
					<Image
						alt={headerConfig.elements.image[0].description ?? ''}
						className="h-8"
						height={32}
						loader={loadImageFromKontentAI}
						src={headerConfig.elements.image[0].url}
						style={{width: 'auto'}}
						width={212}
					/>
				) : null
			}
			logoHrefUrl={headerConfig.elements.imageClickUrl}
			mainBorderColor={mainBorderColor}
			mobileLogo={
				headerConfig.elements.mobileImage[0] ? (
					<Image
						alt={
							headerConfig.elements.mobileImage[0].description ??
							''
						}
						className="mr-2 h-8"
						height={32}
						loader={loadImageFromKontentAI}
						src={headerConfig.elements.mobileImage[0].url}
						style={{width: 'auto'}}
						width={200}
					/>
				) : null
			}
			navItems={
				hasLinksToDisplay ? (
					<NavItems
						className={clsx({
							'invisible hidden': !navVisible,
							'lg:!visible lg:!block': true,
						})}
					>
						<div className="h-screen w-full border-b border-solid bg-gray-100 lg:flex lg:h-auto lg:items-center lg:justify-start lg:bg-transparent">
							<div className="container-wide flex justify-between gap-4 lg:!invisible lg:!hidden">
								<PrimaryButtons
									buttonLinks={headerButtons}
									className="my-2 flex-shrink-0 px-6 py-2"
									loginUrl={globalConfig.elements.loginUrl}
								/>
							</div>
							{customDropdownHasLinks ? (
								<CustomDropdown
									buttonClassName={clsx(
										`p-2 pr-16 pl-2 border-primary`,
										authState.authenticated
											? 'my-2'
											: 'mb-2'
									)}
									className="container-wide z-10 block lg:!invisible lg:!hidden"
									customDropdownIcon={
										<svg
											className="!bottom-0 !right-0 !top-[14px] !w-[10px]"
											clipRule="evenodd"
											fillRule="evenodd"
											imageRendering="optimizeQuality"
											shapeRendering="geometricPrecision"
											style={{
												width: 10,
												top: 14,
												bottom: 0,
												right: 0,
											}}
											textRendering="geometricPrecision"
											viewBox="0 0 512 336.36"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M42.47.01 469.5 0C492.96 0 512 19.04 512 42.5c0 11.07-4.23 21.15-11.17 28.72L294.18 320.97c-14.93 18.06-41.7 20.58-59.76 5.65-1.8-1.49-3.46-3.12-4.97-4.83L10.43 70.39C-4.97 52.71-3.1 25.86 14.58 10.47 22.63 3.46 32.57.02 42.47.01z"
												fill="black"
												fillRule="nonzero"
											/>
										</svg>
									}
									defaultSelectText={
										selectedSpecies?.title ??
										headerConfig.elements
											.dropdownHeaderMenu[0]?.elements
											.text
									}
									dropdownData={species}
									dropdownLabel=""
									itemClassName="w-full flex-row-reverse justify-between py-1 px-2"
									itemTitleClassName="text-base"
									listContainerClassName=""
									onChange={(data: CustomDropdownData) => {
										window.location.href = `/${router.locale}/${data.url}`
									}}
								/>
							) : null}
							<Nav isGlobalNav>
								{getNavLinks(
									authState.authenticated &&
										headerConfig.elements.authenticatedLinks
											.length > 0
										? authenticatedLinksWithLocale
										: headerConfig.elements.links,
									myAccountLinks || []
								)}
							</Nav>
							<div className="lg:!invisible lg:!hidden">
								{/* If on mobile, we need to add the secondary navigation links */}
								{!hasInPageNavigation &&
								secondaryHeaderConfig?.elements.links ? (
									<Nav
										className="mt-4"
										isGlobalNav
										label="Product Navigation"
									>
										{getNavLinks(
											secondaryHeaderConfig.elements.links
										)}
									</Nav>
								) : null}
								<div
									className={
										hasInPageNavigation
											? 'flex flex-shrink flex-col'
											: 'hidden'
									}
								>
									{optionalPortalLinkHasLinks ? (
										<ul className="container-wide flex flex-col items-center justify-between gap-1 capitalize">
											{headerConfig.elements.optionalHeaderLinks.map(
												(optionalHeaderLink, idx) => (
													<div
														className="w-content btn my-2 inline-flex w-full flex-shrink-0 cursor-pointer flex-row items-center justify-center rounded-sm bg-theme-main px-6 py-2 align-middle text-sm hover:bg-theme-highlight focus:bg-secondary"
														key={
															optionalHeaderLink
																.system.id
														}
													>
														{/* @ts-expect-error -- TODO: pass rest of props */}
														<LinkBlock
															block={
																optionalHeaderLink
															}
															className="font-bold text-white"
															globalConfig={
																globalConfig
															}
														/>
														<div className="pl-2 text-white">
															{newIcon(
																ButtonIcons[
																	optionalHeaderLink
																		.elements
																		.icon[0]
																		?.name as keyof typeof ButtonIcons
																].icon,
																idx,
																'w-4 h-4'
															)}
														</div>
													</div>
												)
											)}
										</ul>
									) : null}
								</div>
							</div>
						</div>
					</NavItems>
				) : null
			}
			nonNavItems={
				<ToggleNav
					globalConfig={globalConfig}
					headerState={headerState}
					setHeaderState={setHeaderState}
					toggleSearchPopup={toggleSearchPopup}
				/>
			}
			pinned={navFixed}
		/>
	)
}
